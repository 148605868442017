export {}

if (window.log) {
  Object.assign(console, window.log.functions)
  window.log.info(
    `\n\n   _  __   __     _     ____                    __  ___                    _        
    | |/ /  / /_   (_)   / __/   __  __          /  |/  /  __  __   _____   (_)  _____
    |   /  / __/  / /   / /_    / / / /         / /|_/ /  / / / /  / ___/  / /  / ___/ 
   /   |  / /_   / /   / __/   / /_/ /         / /  / /  / /_/ /  (__  )  / /  / /__  
  /_/|_|  \\__/  /_/   /_/      \\__, /         /_/  /_/   \\__,_/  /____/  /_/   \\___/  
                              /____/\n`
  )
}
